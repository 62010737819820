<template>
	<section>
		<section class="search-container">
			<a-form layout="inline" class="m-t-14">
				<a-form-item label="预警类型：">
					<a-input
						placeholder="请输入"
						v-model="formSearch.typeName"
						allowClear
					/>
				</a-form-item>
				<a-form-item>
					<a-button type="primary" class="m-r-8" @click="btnQuery"
						><a-icon type="search" />查询</a-button
					>
					<a-button class="m-r-8" @click="btnReset"
						><a-icon type="reload" />重置</a-button
					>
					<a-button type="primary" class="m-r-8" @click="editAlarmType()"
						>添加类型</a-button
					>
				</a-form-item>
			</a-form>
		</section>
		<section class="list-table-container m-t-18">
			<a-table
				size="small"
				:columns="columns"
				:data-source="tableData"
				:pagination="{
					total,
					hideOnSinglePage: true,
					current: searchData.current
				}"
				@change="pageNumChange"
			>
				<a slot="action" slot-scope="text, record">
					<a
						v-if="record.allowDelete"
						href="javascript: void(0)"
						class="m-r-10"
						@click="editAlarmType(record)"
						>编辑</a
					>
					<a-popconfirm
						title="确定删除该预警类型?"
						@confirm="deleteItem(record.id)"
						>删除</a-popconfirm
					>
				</a>
			</a-table>
		</section>
		<a-drawer
			class="drawer_module"
			:title="dataSelected ? '编辑预警类型' : '添加预警类型'"
			placement="right"
			:width="360"
			:maskClosable="false"
			:visible="editVisible"
			@close="btnCancel"
		>
			<a-form
				:form="form"
				:colon="false"
				:labelCol="{ span: 6 }"
				:wrapperCol="{ span: 18 }"
			>
				<a-form-item label="预警类型">
					<a-input
						v-decorator="[
							'typeName',
							{ rules: [{ required: true, message: '预警类型为必填项' }] }
						]"
					/>
				</a-form-item>
				<a-form-item :wrapper-col="{ span: 18, offset: 6 }">
					<a-button type="primary" @click="btnConfirm">保存</a-button>
					<a-button style="margin-left: 10px" @click="btnCancel">关闭</a-button>
				</a-form-item>
			</a-form>
		</a-drawer>
	</section>
</template>

<script>
	export default {
		name: 'AlarmType',
		data() {
			return {
				form: this.$form.createForm(this),
				formSearch: {
					typeName: ''
				},
				searchData: {
					typeName: '',
					current: 1,
					size: 10
				},
				columns: [
					{
						title: '序号',
						dataIndex: 'key',
						align: 'center'
					},
					{
						title: '预警类型',
						dataIndex: 'typeName',
						align: 'center'
					},
					{
						title: '操作',
						dataIndex: 'action',
						align: 'center',
						scopedSlots: { customRender: 'action' }
					}
				],
				tableData: [],
				total: 0,
				editVisible: false,
				dataSelected: null
			}
		},
		mounted() {
			this.queryAlarmType()
		},
		watch: {
			editVisible(nVal) {
				if (nVal && this.dataSelected) {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							typeName: this.dataSelected.typeName
						})
					})
				} else {
					this.$nextTick(() => {
						this.form.setFieldsValue({
							typeName: ''
						})
					})
				}
			}
		},
		methods: {
			queryAlarmType() {
				this.$api.queryAlarmType(this.searchData).then((res) => {
					if (res.code === 200) {
						this.tableData = res.data.records
							? res.data.records.map((item, index) => {
									item.key =
										(this.searchData.current - 1) * this.searchData.size +
										index +
										1
									if (item.key <= 5) {
										item.allowDelete = false
									} else {
										item.allowDelete = true
									}
									return item
							  })
							: []
						this.total = res.data.total
					}
				})
			},
			pageNumChange(pagination) {
				this.searchData.current = pagination.current
				this.queryAlarmType()
			},
			btnQuery() {
				this.searchData.typeName = this.formSearch.typeName
				this.searchData.current = 1
				this.queryAlarmType()
			},
			btnReset() {
				this.searchData.typeName = this.formSearch.typeName = ''
				this.searchData.current = 1
				this.queryAlarmType()
			},
			editAlarmType(record) {
				this.dataSelected = record
				this.editVisible = true
			},
			btnConfirm() {
				this.form.validateFields((errors, values) => {
					if (!errors) {
						if (this.dataSelected) {
							this.$api
								.updateAlarmType({
									id: this.dataSelected.id,
									typeName: values.typeName
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmType()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						} else {
							this.$api
								.insertAlarmType({
									typeName: values.typeName
								})
								.then((res) => {
									if (res.code === 200) {
										this.editVisible = false
										this.queryAlarmType()
									}
								})
								.catch((err) => {
									console.log(err)
								})
						}
					} else {
						return false
					}
				})
			},
			btnCancel() {
				this.form.resetFields()
				this.editVisible = false
			},
			deleteItem(id) {
				this.$api
					.deleteAlarmType(id)
					.then((res) => {
						if (res.code === 200) {
							if (this.tableData.length === 1 && this.searchData.current > 1) {
								this.searchData.current -= 1
							}
							this.queryAlarmType()
						}
					})
					.catch((err) => {
						console.log(err)
					})
			}
		}
	}
</script>
